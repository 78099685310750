<template>
  <div class="h5-login">
    <div class="loading-wrapper" v-if="loading">
      <div class="loading-content">
        <div class="loading_icon">
          <img src="https://pic.eaworks.cn/chenghua/loading_icon.png" alt="">
        </div>
        <div class="loading_txt">
          <img src="https://pic.eaworks.cn/chenghua/loading_txt.png" alt="">
        </div>
      </div>
    </div>
    <div v-else>
      <div class="login-wrapper" v-if="showLoginWrapper">
        <div class="index_title" v-if="showLoginIcon">
          <img src="https://pic.eaworks.cn/chenghua/index_title.png" alt="">
        </div>
        <div class="login_icon" v-if="showLoginIcon">
          <img src="https://pic.eaworks.cn/chenghua/login_icon.png" alt="">
        </div>
        <div class="login_btn" v-if="showLoginBtn">
          <img src="https://pic.eaworks.cn/chenghua/login_btn.png" @click="onClickHome" alt="">
        </div>
      </div>
    </div>
    <div class="login-fixed-img">
      <img src="https://pic.eaworks.cn/chenghua/index_main_bg.png" alt="">
      <img src="https://pic.eaworks.cn/chenghua/login_icon.png" alt="">
      <img src="https://pic.eaworks.cn/chenghua/login_btn.png" alt="">
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import wx from 'weixin-js-sdk'
import page_wx from "../mixin/page_wx";

export default {
  mixins: [page_wx],
  components: {},
  data() {
    return {
      pageHeight: 1200,
      loading: true,
      showLoginWrapper: false,
      showLoginIcon: false,
      showLoginBtn: false,
    };
  },

  created() {
    if (this.$route.query.tester && this.$route.query.token) {
      localStorage.setItem('_wx_code', this.$route.query.token);
      Cookies.set('chenghua_h5', this.$route.query.token);
    } else if (this.$route.query.logout) {
      Cookies.remove('chenghua_h5');
      localStorage.clear();
    }
    if(this.$route.query.tester){
      // 测试专用
      this.loadingLogin();
    } else {
      // this.checkWeiXinCode(() => {
         this.loadingLogin();
      // });
    }
  },

  mounted() {
    console.log("-> [login.vue] mounted");
    this.pageHeight = window.innerHeight || 1200;
    this.getJsInfo();
  },

  methods: {
    loadingLogin() {
      setTimeout(() => {
        this.loading = false;
        this.$nextTick(() => {
          this.showLoginWrapper = true;
          setTimeout(() => {
            this.showLoginIcon = true;
            setTimeout(() => {
              this.showLoginBtn = true;
            }, 2000);
          }, 500);
        })
      }, 3000);
    },

    onClickHome() {
      this.$router.push("/home")
    },

    async getJsInfo() {
      // 生成网页js签名
      let {data, code} = await this.$ajax.gateway("/api/api_get_js_sign", {
        "body": {
          "url": location.href.split('#')[0]
        }
      });
      if (code == 200) {
        wx.config(data.res);
      }
      wx.ready(function () {
        wx.updateAppMessageShareData({
          title: '寻味成华美食指南', // 分享标题
          desc: '2024品味成都·食在成华·地道美食精选', // 分享描述
          link: 'https://chqmsdt.eaworks.cn/page', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'https://pic.eaworks.cn/chenghua/1.png', // 分享图标
          success: function (res) {
            // 设置成功
          }
        });
        wx.updateTimelineShareData({
          title: '寻味成华美食指南', // 分享标题
          desc: '2024品味成都·食在成华·地道美食精选', // 分享描述
          link: 'https://chqmsdt.eaworks.cn/page', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'https://pic.eaworks.cn/chenghua/1.png', // 分享图标
          success: function (res) {
            // 用户点击了分享后执行的回调函数
          }
        });
      });
      wx.error(function (res) {
        console.info("wx error", res);
      });
    },
  }
};
</script>

<style lang="less" scoped>
.h5-login {

}

.loading-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("https://pic.eaworks.cn/chenghua/loading_bg.png") no-repeat top center;
  background-size: 100% 100%;

  .loading-content {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -1rem;

    .loading_icon {
      text-align: center;

      img {
        display: block;
        height: 1.1rem;
        margin: 0 auto;
      }
    }

    .loading_txt {
      text-align: center;
      padding-top: 0.1rem;

      img {
        display: block;
        height: 0.3rem;
        margin: 0 auto;
        -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
        animation: heartbeat 1.5s ease-in-out infinite both;
      }
    }
  }
}

.login-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("https://pic.eaworks.cn/chenghua/0509.png") no-repeat top center;
  background-size: 100% 100%;
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

  .login_icon {
    position: absolute;
    bottom: calc(12vh);
    right: calc(6vh);

    img {
      display: block;
      width: calc(13vh);
      -webkit-animation: slide-in-right 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      animation: slide-in-right 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
  }
  .index_title {
    img {
      width: 100%;
    }
  }

  .login_btn {
    width: 100%;
    position: absolute;
    bottom: 0.3rem;
    left: 0;

    img {
      width: 78%;
      display: block;
      margin: 0 auto;
      -webkit-animation: heartbeat 2s ease-in-out infinite both;
      animation: heartbeat 2s ease-in-out infinite both;
    }
  }
}

.login-fixed-img {
  width: 1px;
  height: 1px;
  position: absolute;
  top: -9999px;
  left: -9999px;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
